// Bootstrap Overrides
$body-color:                        #41505f;
$body-bg:                           #fbfcfe;
$cards-bg:                          #fbfcfe;
$input-bg:                          #fff;

$gray-100:                          #f8f9fc;
$gray-200:                          #f3f5f9;
$gray-300:                          #e2e5ec;
$gray-400:                          #ccd2da;
$gray-500:                          #9ea7b1;
$gray-600:                          #6e7985;
$gray-700:                          #41505f;
$gray-800:                          #313c47;
$gray-900:                          #212830;

// Theme Colors
$color-ui-01:                       #2845f9;
$color-ui-02:                       #85b6ff;
$color-ui-03:                       #4c5366;
$color-ui-04:                       #e9ebf1;

$color-tx-01:                       $gray-900;
$color-tx-02:                       $gray-700;
$color-tx-03:                       $gray-600;

$primary:                           $color-ui-01;
$chart-secondary:                   #4f6fd9;
$success:                           #0cb785;
$teal:                              #33d685;
$pink:                              #ea4c89;

// Dark Theme Colors
$dark-primary:                      #141926;
$dark-highlight:                    #192030;
$dark-input-focused:                #1e273a;
$dark-table-border:                 #222b41;
$dark-context-menu-border:          #2b3651;
$dark-cell-text-color:              rgba(255, 255, 255, 0.6);

// Charts
$map-start-color:                   #C8EEFF;
$column-chart-color:                #c1ccf1;
$border-chart-color:                rgba(72,94,144, 0.07);
$gradient-to-colors:                #f3f5fc;
$xaxis-labels-color:                #6e7985;
$datepicker-hover-color:            #1675e0;
$datepicker-border-color:           rgba(255, 255, 255, 0.06);
$subtext-chart-color:               rgb(175, 177, 183);

// Three charts colors
$chart-color-1:                     $column-chart-color;
$chart-color-2:                     $primary;
$chart-color-3:                     $success;


// Borders
$border-radius:                     0.25rem;
$border-color-dark:                 lighten($dark-primary, 8%);
$border-color-focused:              #27314b;

// Buttons
$input-btn-padding-y:               .406rem;


// Fonts
$ff-primary:                        'Open Sans', sans-serif;
$ff-secondary:                      'Archivo', sans-serif;
$ff-numerals:                       'Inter', sans-serif;

$font-family-base:                  $ff-primary;

$font-weight-base:                  400;
$font-weight-light:                 300;
$font-weight-medium:                500;
$font-weight-semibold:              600;
$font-weight-bold:                  700;

$font-size-base:                    0.875rem;
$font-size-sm:                      0.8125rem;
$font-size-xs:                      0.75rem;
$font-size-md:                      0.938rem;

// Links
$link-decoration:                   none;

// Size
$height-header:                     72px;
$height-base:                       36px;
$sidebar-width:                     250px;
$logo-max-width:                    90px;
$logo-height:                       27px;
$role-sign-left:                    93%;
$role-sign-bottom:                  -24px;

// Shadows
$box-shadow:                        0 .5rem 1rem rgba($gray-900, .15) !default;
$box-shadow-sm:                     0 .125rem .25rem rgba($gray-900, .075) !default;
$box-shadow-lg:                     0 1rem 3rem rgba($gray-900, .175) !default;
$box-shadow-inset:                  inset 0 1px 2px rgba($gray-900, .075) !default;

// Light Tables Colors
// $light-table-odd-row-bg-color:       #f1f1f1;   // Lightened version for odd rows
// $light-table-row-bg-color:           #ffffff;   // Pure white for regular rows
// $light-table-header-bg-color:        #f1f1f1;   // Soft grey for headers
// $light-table-pagination-bg-color:    #f1f1f1;   // Matches header for consistency
// $light-table-hover-row-bg-color:     #dce8ff;   // Light blue for hover effect
// $light-table-hover-search-bg-color:  #e6e6e6;   // Subtle grey for search hover
// $light-table-search-bg-color:        #f9f9f9;   // Matches odd-row background
// $light-table-border-color:           #d3d3d3;   // Light grey for borders
// $light-table-font-color:             #000;      // Black for text

$light-table-odd-row-bg-color:       #F4F4F480;   // Lightened version for odd rows
$light-table-row-bg-color:           #ffffff;   // Pure white for regular rows
$light-table-header-bg-color:        #F4F4F4;   // Soft grey for headers
$light-table-pagination-bg-color:    #ffffff;   // Matches header for consistency
$light-table-hover-row-bg-color:     #dce8ff;   // Light blue for hover effect, old value: #dce8ff
$light-table-hover-search-bg-color:  #ffffffe5;   // Subtle grey for search hover
$light-table-search-bg-color:        #ffffff;   // Matches odd-row background
$light-table-border-color:           #d3d3d3;   // Light grey for borders
$light-table-font-color:             #181D1F;      // Black for text

// Dark Tables Colors
$dark-table-odd-row-bg-color:       #18203080; // Darkened version for odd rows #131A26
$dark-table-row-bg-color:           rgba(29,36,54);  // Dark blue for regular rows, old value: #00000020
$dark-table-header-bg-color:        #182030; // Matches row color for consistency
$dark-table-pagination-bg-color:    #182030; // Matches header for consistency
$dark-table-hover-row-bg-color:     #2844f95b; // Primary color for hover effect, old value: #2845F9 || #2844f95b
$dark-table-hover-search-bg-color:  #192030; // Darkened version for search hover
$dark-table-search-bg-color:        #182030; // Matches odd-row background
$dark-table-border-color:           #3A3E49; // Dark grey for borders
$dark-table-font-color:             #FFF;   // White for text

:root {
  --primary: #{$primary};
  --chart-secondary: #{$chart-secondary};
  --column-chart: #{$column-chart-color};
  --border-chart: #{$border-chart-color};

  --gradient-to-colors: #{$gradient-to-colors};
  --xaxis-labels: #{$xaxis-labels-color};
  --map-start: #{$map-start-color};

  --chart-color-1: #{$chart-color-1};
  --chart-color-2: #{$chart-color-2};
  --chart-color-3: #{$chart-color-3};

  // Table Colors
  --light-table-odd-row-bg-color: #{$light-table-odd-row-bg-color};
  --light-table-row-bg-color: #{$light-table-row-bg-color};
  --light-table-header-bg-color: #{$light-table-header-bg-color};
  --light-table-pagination-bg-color: #{$light-table-pagination-bg-color};
  --light-table-hover-row-bg-color: #{$light-table-hover-row-bg-color};
  --light-table-hover-search-bg-color: #{$light-table-hover-search-bg-color};
  --light-table-search-bg-color: #{$light-table-search-bg-color};
  --light-table-border-color: #{$light-table-border-color};
  --light-table-font-color: #{$light-table-font-color};

  --dark-table-odd-row-bg-color: #{$dark-table-odd-row-bg-color};
  --dark-table-row-bg-color: #{$dark-table-row-bg-color};
  --dark-table-header-bg-color: #{$dark-table-header-bg-color};
  --dark-table-pagination-bg-color: #{$dark-table-pagination-bg-color};
  --dark-table-hover-row-bg-color: #{$dark-table-hover-row-bg-color};
  --dark-table-hover-search-bg-color: #{$dark-table-hover-search-bg-color};
  --dark-table-search-bg-color: #{$dark-table-search-bg-color};
  --dark-table-border-color: #{$dark-table-border-color};
  --dark-table-font-color: #{$dark-table-font-color};
}

// OLD CODE
// // Bootstrap Overrides
// $body-color:                        #41505f;
// $body-bg:                           #fbfcfe;
// $cards-bg:                          #fbfcfe;
// $input-bg:                          #fff;

// $gray-100:                          #f8f9fc;
// $gray-200:                          #f3f5f9;
// $gray-300:                          #e2e5ec;
// $gray-400:                          #ccd2da;
// $gray-500:                          #9ea7b1;
// $gray-600:                          #6e7985;
// $gray-700:                          #41505f;
// $gray-800:                          #313c47;
// $gray-900:                          #212830;

// // Theme Colors
// $color-ui-01:                       #2845f9;
// $color-ui-02:                       #85b6ff;
// $color-ui-03:                       #4c5366;
// $color-ui-04:                       #e9ebf1;

// $color-tx-01:                       $gray-900;
// $color-tx-02:                       $gray-700;
// $color-tx-03:                       $gray-600;

// $primary:                           $color-ui-01;
// $chart-secondary:                   #4f6fd9;
// $success:                           #0cb785;
// $teal:                              #33d685;
// $pink:                              #ea4c89;

// // Dark Theme Colors
// $dark-primary:                      #141926;
// $dark-highlight:                    #192030;
// $dark-input-focused:                #1e273a;
// $dark-table-border:                 #222b41;
// $dark-context-menu-border:          #2b3651;
// $dark-cell-text-color:              rgba(255, 255, 255, 0.6);

// // Charts
// $map-start-color:                   #C8EEFF;
// $column-chart-color:                #c1ccf1;
// $border-chart-color:                rgba(72,94,144, 0.07);
// $gradient-to-colors:                #f3f5fc;
// $xaxis-labels-color:                #6e7985;
// $datepicker-hover-color:            #1675e0;
// $datepicker-border-color:           rgba(255, 255, 255, 0.06);
// $subtext-chart-color:               rgb(175, 177, 183);

// // Three charts colors
// $chart-color-1:                     $column-chart-color;
// $chart-color-2:                     $primary;
// $chart-color-3:                     $success;


// // Borders
// $border-radius:                     0.25rem;
// $border-color-dark:                 lighten($dark-primary, 8%);
// $border-color-focused:              #27314b;

// // Buttons
// $input-btn-padding-y:               .406rem;


// // Fonts
// $ff-primary:                        'Open Sans', sans-serif;
// $ff-secondary:                      'Archivo', sans-serif;
// $ff-numerals:                       'Inter', sans-serif;

// $font-family-base:                  $ff-primary;

// $font-weight-base:                  400;
// $font-weight-light:                 300;
// $font-weight-medium:                500;
// $font-weight-semibold:              600;
// $font-weight-bold:                  700;

// $font-size-base:                    0.875rem;
// $font-size-sm:                      0.8125rem;
// $font-size-xs:                      0.75rem;
// $font-size-md:                      0.938rem;

// // Links
// $link-decoration:                   none;

// // Size
// $height-header:                     72px;
// $height-base:                       36px;
// $sidebar-width:                     250px;
// $logo-max-width:                    90px;
// $logo-height:                       27px;
// $role-sign-left:                    93%;
// $role-sign-bottom:                  -24px;

// // Shadows
// $box-shadow:                        0 .5rem 1rem rgba($gray-900, .15) !default;
// $box-shadow-sm:                     0 .125rem .25rem rgba($gray-900, .075) !default;
// $box-shadow-lg:                     0 1rem 3rem rgba($gray-900, .175) !default;
// $box-shadow-inset:                  inset 0 1px 2px rgba($gray-900, .075) !default;

// // Tables
// $light-table-bg:                    #4C5ECF1F;
// $light-table-border:                #E2E5EC8C;
// $dark-table-font-color:             #FFFFFF9E;
// $dark-table-border-color:           #3a3e49;
// $dark-table-hover-bg-color:         #4C5ECF0D;

// :root {
//     --primary: #{$primary};
//     --chart-secondary: #{$chart-secondary};
//     --column-chart: #{$column-chart-color};
//     --border-chart: #{$border-chart-color};

//     --gradient-to-colors: #{$gradient-to-colors};
//     --xaxis-labels: #{$xaxis-labels-color};
//     --map-start: #{$map-start-color};

//     --chart-color-1: #{$chart-color-1};
//     --chart-color-2: #{$chart-color-2};
//     --chart-color-3: #{$chart-color-3};
//   }