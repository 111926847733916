@import "../_variables.scss";

// read here: https://www.ag-grid.com/vue-data-grid/theming-v32-customisation-variables/#reference-variables---ag-odd-row-background-color

.input-search {
    width: 100%;
    padding: 8px; 
    box-sizing: border-box; 
    border: 1px solid #ddddde;
    border-radius: 7px;
}

//^ DARK THEME

// general theme
.ag-theme-quartz-dark {
    font-family: "Open Sans", sans-serif !important;
    --ag-font-size: 14px !important;
    --ag-cell-widget-spacing: 5px !important;
    --ag-cell-horizontal-padding: 10px !important;
    --ag-border-radius: 4px !important;
    
    --ag-background-color: var(--dark-table-row-bg-color) !important;
    --ag-odd-row-background-color: var(--dark-table-odd-row-bg-color) !important;
    // --ag-row-hover-color: var(--dark-table-hover-row-bg-color) !important;
    // --ag-column-hover-color: var(--dark-table-hover-row-bg-color) !important;
    // --ag-header-cell-hover-background-color: var(--dark-table-hover-row-bg-color) !important;
    --ag-alpine-active-color: var(--dark-table-hover-row-bg-color) !important;
    --ag-row-border-color: var(--dark-table-border-color) !important;
    --ag-header-column-separator-color: var(--dark-table-border-color) !important;
    --ag-header-background-color: var(--dark-table-header-bg-color) !important;
}

// search input above the table
.ag-theme-quartz-dark .input-search {
    background-color: var(--dark-table-search-bg-color) !important;
    border: 1px solid var(--dark-table-border-color) !important;

    &:focus-visible{
       outline-style: none !important;
       background-color: var(--dark-table-hover-search-bg-color) !important;
    }
}

// left header part
.ag-theme-quartz-dark .ag-pinned-left-header {
    background-color: var(--dark-table-header-bg-color) !important;
}

// middle header part
.ag-theme-quartz-dark .ag-header-container {
    background-color: var(--dark-table-header-bg-color) !important;
}

// right header part
.ag-theme-quartz-dark .ag-pinned-right-header {
    background-color: var(--dark-table-header-bg-color) !important;
}

// normal row 
.ag-theme-quartz-dark .ag-body .ag-body-viewport {
    background-color: var(--dark-table-row-bg-color) !important;
}

// pagination
.ag-theme-quartz-dark .ag-paging-panel {
    border-top-color: var(--dark-table-border-color) !important;
    background-color: var(--dark-table-pagination-bg-color) !important;
    color: var(--dark-table-font-color) !important;
}

// loading row
.ag-theme-quartz-dark .ag-row-loading {
    background-color: var(--dark-table-row-bg-color) !important;
    color: var(--dark-table-font-color) !important;
}


//^ WHITE THEME

// general theme
.ag-theme-quartz-light {
    font-family: "Open Sans", sans-serif !important;
    --ag-font-size: 14px !important;
    --ag-cell-widget-spacing: 5px !important;
    --ag-cell-horizontal-padding: 10px !important;
    --ag-border-radius: 4px !important;

    --ag-odd-row-background-color: var(--light-table-odd-row-bg-color) !important;
    // --ag-row-hover-color: var(--light-table-hover-row-bg-color) !important;
    // --ag-column-hover-color: var(--light-table-hover-row-bg-color) !important;
    // --ag-header-cell-hover-background-color: var(--light-table-hover-row-bg-color) !important;
    --ag-alpine-active-color: var(--light-table-hover-row-bg-color) !important;
    --ag-row-border-color: var(--light-table-border-color) !important;
    --ag-header-column-separator-color: var(--light-table-border-color) !important;
    --ag-header-background-color: var(--light-table-header-bg-color) !important;
}

// search input above the table
.ag-theme-quartz-light .input-search {
    background-color: var(--light-table-search-bg-color) !important;
    border: 1px solid var(--light-table-border-color) !important;

    &:focus-visible{
       outline-style: none;
       background-color: var(--light-table-hover-search-bg-color) !important;
    }
}

// left header part
.ag-theme-quartz-light .ag-pinned-left-header {
    background-color: var(--light-table-header-bg-color) !important;
}

// middle header part
.ag-theme-quartz-light .ag-header-container {
    background-color: var(--light-table-header-bg-color) !important;
}

// right header part
.ag-theme-quartz-light .ag-pinned-right-header {
    background-color: var(--light-table-header-bg-color) !important;
}

// normal row
.ag-theme-quartz-light .ag-body .ag-body-viewport {
    background-color: var(--light-table-row-bg-color) !important;
}

// pagination
.ag-theme-quartz-light .ag-paging-panel {
    border-top-color: var(--light-table-border-color) !important;
    background-color: var(--light-table-pagination-bg-color) !important;
    color: var(--light-table-font-color) !important;
}

// loading row
.ag-theme-quartz-light .ag-row-loading {
    background-color: var(--light-table-row-bg-color) !important;
    color: var(--light-table-font-color) !important;
}


// OLD CODE
// @import "../variables";


// .input-search{
//     width: 100%;
//     padding: 8px; 
//     box-sizing: border-box; 
//     border: 1px solid #ddddde;
//     border-radius: 7px;
// }

// //^ DARK THEME
// .ag-theme-quartz-dark {
//     font-family: "Open Sans", sans-serif;
// }

// .ag-theme-quartz-dark .ag-cell{
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
//     color: $dark-table-font-color
// }

// .ag-theme-quartz-dark .input-search{
//     background-color: $dark-primary;
//     border: 1px solid $dark-table-border-color;

//     &:focus-visible{
//        outline-style: none;
//        background-color: $dark-table-hover-bg-color
//     }
// }

// .ag-theme-quartz-dark .ag-row-hover .ag-cell{
//     background-color: $dark-table-hover-bg-color !important
// }

// .ag-theme-quartz-dark .ag-header-container {
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
// }

// .ag-theme-quartz-dark  .ag-header {
//     border-bottom-color: $dark-table-border-color;
// }

// .ag-theme-quartz-dark .ag-root-wrapper{
//     border: 1px solid $dark-table-border-color;
    
// }

// .ag-theme-quartz-dark .ag-header-viewport {
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
// }

// .ag-theme-quartz-dark .ag-pinned-right-header {
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
// }

// .ag-theme-quartz-dark .ag-pinned-left-header {
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
// }

// .ag-theme-quartz-dark .ag-body .ag-body-viewport {
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
// }

// .ag-theme-quartz-dark .ag-row {
//     // border-color: $dark-table-border-color;
//     background-color: $dark-highlight;
// }

// .ag-theme-quartz-dark .ag-paging-panel {
//     border-top-color: $dark-table-border-color;
//     // background-color: $dark-primary;
//     background-color: $dark-highlight;
//     color: $dark-table-font-color ;
// }
// .ag-theme-quartz-dark .ag-paging-page-summary-panel .ag-icon{
//     color:$dark-table-font-color !important;
// }

// .ag-theme-quartz-dark .ag-row-loading {
//     // background-color: $dark-table-hover-bg-color;
//     background-color: $dark-highlight;
//     color: $dark-table-font-color ;
// }

// //^ WHITE THEME
// .ag-theme-quartz {
//     font-family: "Open Sans", sans-serif;
// }

// .ag-theme-quartz .ag-row-hover .ag-cell{
//     background-color: $light-table-bg
// }

// .ag-theme-quartz .ag-cell{
//     background-color: $cards-bg;
//     color: $gray-900
// }

// .ag-theme-quartz  .input-search{
//     background-color: $cards-bg;
//     border: 1px solid $light-table-border;

//     &:focus-visible{
//        outline-style: none;
//        background-color: #4E4C4C05
//     }
// }

// .ag-theme-quartz .ag-header-container {
//     background-color: $cards-bg;
// }

// .ag-theme-quartz .ag-header-viewport {
//     background-color: $cards-bg;
// }

// .ag-theme-quartz  .ag-header {
//     border-bottom-color: $light-table-border;
// }

// .ag-theme-quartz .ag-pinned-left-header {
//     background-color: $cards-bg;
// }

// .ag-theme-quartz  .ag-header {
//     border-bottom-color: $light-table-border;
// }

// .ag-theme-quartz .ag-root-wrapper{
//     border: 1px solid $light-table-border;
    
// }

// .ag-theme-quartz .ag-body .ag-body-viewport {
//     background-color: $cards-bg;
// }

// .ag-theme-quartz .ag-row {
//     border-color: $light-table-border;
// }

// .ag-theme-quartz .ag-paging-panel {
//     border-top-color: $light-table-border;
//     background-color: $cards-bg;
//     color:$gray-900 ;
// }

// .ag-theme-quartz .ag-row-loading {
//     background-color: $light-table-bg;
//     color: $gray-900;
// }