:root {
  // Colors palette
  // See: https://rsuitejs.com/resources/palette/
  --rs-primary-50: #e8eaff;   // Lightest
  --rs-primary-100: #c8ccff;
  --rs-primary-200: #a8adff;
  --rs-primary-300: #8890ff;
  --rs-primary-400: #6872ff;
  --rs-primary-500: #2845f9;   // Base color
  --rs-primary-600: #2839e0;
  --rs-primary-700: #282dc7;
  --rs-primary-800: #2821ae;
  --rs-primary-900: #281595;   // Darkest

  // Spectrum
  --rs-color-red: #f44336;
  --rs-color-orange: #fa8900;
  --rs-color-yellow: #ffb300;
  --rs-color-green: #4caf50;
  --rs-color-cyan: #00bcd4;
  --rs-color-blue: #2196f3;
  --rs-color-violet: #673ab7;
}

[data-skin="dark"] {
    --rs-border-primary: rgba(255, 255, 255, 0.06);
    .rs-picker-toggle-default {
        background-color: #141926;
        color: #fff;
    }
    .rs-picker-menu {
        background-color: #141926!important;
        color: #fff;
    }
}

.rs-input-group.rs-input-group-inside {
    display: none;
}
